import * as Msdyn365 from '@msdyn365-commerce/core';
import { Cart, CartLine } from '@msdyn365-commerce/retail-proxy';
import { addCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

interface IAddCartLineInput {
    cartId: string;
    cartLines?: CartLine[] | null;
    cartVersion?: number | null;
}

export class AddCartLinesInput implements Msdyn365.IActionInput {
    public objInput: IAddCartLineInput = {
        cartId: '',
        cartLines: null,
        cartVersion: null
    };
    constructor(obj: IAddCartLineInput) {
        this.objInput = obj;
    }

    public getCacheKey = () => `AddCartLines_${this.objInput.cartId}`;
    public getCacheObjectType = () => 'AddCartLines';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

export interface IAddCartLinesData {
    data: Cart | null;
    error: any;
}

const createInput = (obj: IAddCartLineInput, args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new AddCartLinesInput(obj);
};

export async function addToCartLinesCustom(input: AddCartLinesInput, ctx: Msdyn365.IActionContext): Promise<IAddCartLinesData> {
    const { cartId, cartLines, cartVersion } = input.objInput;
    try {
        const data = await addCartLinesAsync({ callerContext: ctx }, cartId, cartLines, cartVersion);
        console.log('Res Data', data);
        return { data, error: null };
    } catch (error) {
        console.log('Error Occured', error);
        return { error: error, data: null };
    }
}

export const addToCartLinesCustomDataAction = Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IAddCartLinesData>>addToCartLinesCustom,
    id: 'AddCartLinesCustom',
    input: createInput
});

export default addToCartLinesCustomDataAction;
